import * as React from 'react';

import HomePage from '../pages/home';

// markup
const IndexPage = () => {
  return (
    <main>
      <HomePage />
    </main>
  );
};

export default IndexPage;
